<template>
    <div class="news">
        <div class="product_img">
            <img class="" :src="backImg" alt="">
        </div>
        
        <div class="main content">
            <div class="btns">
                <div class="btnItem" @click="handleBtn(index)" :class="current == index?'active':''" v-for="(item,index) in btsArr" :key="item.id"> 
                    <p>{{item.name}}</p>
                </div>
                <div class="down">
                    <a href="./EQY.pdf" download="1359-12-EQY产品.pdf" class="marr20">
                      <el-button type="primary">量子点和新型发光材料</el-button>
                    </a>
                    <a href="./products.pdf" download="世纪星辰产品简介.pdf">
                        <el-button type="primary">世纪星辰产品简介</el-button>
                    </a>
                </div>
            </div>
            <template v-if="current == 0">
                <div class="newWrap" v-for="item in newLIst" :key="item.id">
                    <el-card>
                        <div class="elCard">
                            <div class="imgae"><img :src="item.img" alt="" width='400'></div>
                            
                            <div class="card_center">
                                <p class="title">{{item.title}}</p>
                                <div class="smallTitle">{{item.content}}</div>
                            </div>
                            <div class="card_right">
                                <div class="date">{{item.date}}</div>
                                <div class="time">{{item.time}}</div>
                            </div>
                        </div>
                    </el-card>
                </div>
            </template>
            <template v-if="current == 1">
                <div class="newWrap" v-for="item in industryList" :key="item.id">
                    <el-card>
                        <div class="elCard">
                            <div class="imgae"><img :src="item.img" alt="" width='400'></div>
                            
                            <div class="card_center">
                                <p class="title">{{item.title}}</p>
                                <div class="smallTitle">{{item.content}}</div>
                            </div>
                            <div class="card_right">
                                <div class="date">{{item.date}}</div>
                                <div class="time">{{item.time}}</div>
                            </div>
                        </div>
                    </el-card>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            current: 1,
            backImg: require('@/assets/images/banner/news.jpg'),
            // btsArr: [{name:'公司新闻',id:1},{name:'行业新闻',id:2}],
            btsArr: [{name:'行业新闻',id:2}],
            newLIst:[
                // {
                //     id:1,
                //     img:require('@/assets/images/news/new1.jpg'),
                //     title:'辉景助力徕卡杯全国金相大赛圆满成功',
                //     date:'2017',
                //     time: '10-19',
                //     content:'10月9日至13日，由教育部高等学校材料类专业教学指导委员会主办，南昌大学、南昌航空大学、江西科技师范大学联合承办的第六届“徕卡杯”全国大学生金相比赛在南昌取得'
                // },
                // {
                //     id:2,
                //     img:require('@/assets/images/news/new2.jpg'),
                //     title:'徕卡DVM6演示—烽火科技站',
                //     date:'2017',
                //     time: '05-18',
                //     content:'徕卡DVM6演示—烽火科技站'
                // },
                // {
                //     id:3,
                //     img:require('@/assets/images/news/new3.jpg'),
                //     title:'我公司代表参加美墨尔特代理商大会',
                //     date:'2017',
                //     time: '05-03',
                //     content:'鹰击万里，追梦笃行; 2017年3月24日至26日，美墨尔特全体代理商齐聚江南魅力城市——江苏无锡。总结2016'
                // },
                // {
                //     id:4,
                //     img:require('@/assets/images/news/new4.jpg'),
                //     title:'帝肯TECAN：如何开发最佳的荧光检测方案',
                //     date:'2017',
                //     time: '04-10',
                //     content:'借助荧光检测技术您可以进行一些极端敏感，高稳健性且具有广泛动态范围的检测实验，而试验的成功取决于下文所述的几点：激发波长（Ex）和发射波长（Em）的谨慎选择'
                // },
            ],
            industryList:[
                {
                    id:11,
                    img:require('@/assets/images/news/hangye1.jpg'),
                    title:'Tecan资讯 | 工作站如何加速生物仿制',
                    date:'2017',
                    time: '10-19',
                    content:'由于生物制剂性质复杂，在将新型生物制剂投入市场之前，需要对其生产技术和体内效应进行大量测试。Coherus Biosciences正在采用先进的自动化实验室技术，为其新型生物'
                },
                {
                    id:22,
                    img:require('@/assets/images/news/hangye2.jpg'),
                    title:'英斯特朗活动：参加中国国际科学仪器及',
                    date:'2017',
                    time: '04-10',
                    content:'4月6-8日，英斯特朗中国将在北京中国国家会议中心（地址： 北京市朝阳区天辰东路 7号，展台号：T37B）首次亮相素有中国科学仪器领域规模最大、水平最高展会之称的第十五'
                },
                {
                    id:33,
                    img:require('@/assets/images/news/hangye3.jpg'),
                    title:'英斯特朗动态疲劳业务全球事件连线',
                    date:'2017',
                    time: '04-07',
                    content:'AVE2.0 英斯特朗视频引伸计与动态疲劳测试系统的配套已经正式发布。随着静态测试的成功，新增配合动态测试的功能将助力全球数亿万实验室带来一场新的应变测量革命。'
                },
                {
                    id:44,
                    img:require('@/assets/images/news/hangye4.jpg'),
                    title:'中国医用冷藏箱温度稳定的新突破',
                    date:'2017',
                    time: '04-07',
                    content:'以最合理的价格、最完善的服务，提供最优质的产品'
                },
            ]
        }
    },
    mounted(){
    },
    methods:{
        handleBtn(index){
            this.current = index
        }
    }
}
</script>
<style scoped lang="scss">
.none{
    display: none;
}
.marr20{
  margin-right: 20px;
}
.news{
    padding-bottom: 50px;
    background: #f6fbff;
    .content{
        margin-top: 40px;
        .btns{
            margin-bottom: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // cursor: pointer;
            .btnItem{
                font-size: 30px;
                margin-right:30px;
                position: relative;
                .line{
                    position: absolute;
                    right:-16px;
                    top:50%;
                    transform: translateY(-50%);
                    display: inline-block;
                    width:2px;
                    height:20px;
                    background: #DBDBDB;
                    
                }
                &:nth-child(2){
                    .line{
                       display: none; 
                    } 
                }
            }
            .active{
                color: #0075b5;
            }
            .down{
                font-size: 16px;
                font-weight: bold;
            }
        }
        
        .newWrap{
            margin-bottom: 20px;
        }
        .elCard{
            display: flex;
            justify-content: space-between;
            &:hover img{
                transform: scale(1.1);
            }
            .imgae{
                flex: 0 0 400px;
                img{
                   transition: all 0.5s linear;
                   height: 268px; 
                }
                
            }
            .card_center{
                padding:20px;
                box-sizing: border-box;
                .title{
                    padding: 5px 0;
                    max-height: 48px;
                    color: #0075b5;
                    font-size: 30px;
                    border-bottom: solid 0px #DBDBDB;
                }
                .smallTitle{
                    margin-top:20px;
                    overflow: hidden;
                    line-height: 28px;
                    color: #a6a6a6;
                    font-size: 14px;
                    text-indent: 2em;
                }
            }
            .card_right{
                flex: 0 0 200px;
                border-left:1px solid #a6a6a6;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                .date{
                    font-size: 35px;
                    color: #0075b5;
                    line-height: 45px;
                    display: block;
                    text-align: left;
                }
                .time{
                    font-size: 18px;
                    color: #a7a7a7;
                    line-height: 30px;
                    display: block;
                    text-align: left;
                }
            }
        }
    }
}
</style>